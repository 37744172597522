import React from "react";

const Facebook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 512 512"
    >
      <path d="M297.3 508.7c-2.1.3-4.3.7-6.4 1 2.1-.4 4.2-.7 6.4-1zM302.4 507.8c-1 .2-2 .4-3.1.5 1.1-.1 2.1-.3 3.1-.5zM285.1 510.3c-2.5.3-5 .5-7.5.7 2.5-.1 5-.4 7.5-.7zM290.1 509.7c-1.2.2-2.4.3-3.6.4 1.1-.1 2.4-.2 3.6-.4zM309.4 506.4l-2.7.6 2.7-.6zM326.7 502.1c-.7.2-1.5.4-2.2.6.7-.2 1.4-.4 2.2-.6zM321.4 503.5l-2.4.6c.9-.1 1.6-.3 2.4-.6zM314.6 505.3c-.8.2-1.7.4-2.5.6.8-.3 1.7-.5 2.5-.6zM277.5 511.1c-1.3.1-2.7.2-4 .3 1.3-.1 2.7-.2 4-.3z" />
      <path
        fill="currentColor"
        d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256s114.6 256 256 256c1.5 0 3 0 4.5-.1V312.7h-55v-64.1h55v-47.2c0-54.7 33.4-84.5 82.2-84.5 23.4 0 43.5 1.7 49.3 2.5v57.2h-33.6c-26.5 0-31.7 12.6-31.7 31.1v40.8h63.5l-8.3 64.1h-55.2v189.5C433.7 471.4 512 372.9 512 256z"
      />
      <path d="M272.9 511.4c-2.7.2-5.3.3-8 .4 2.7-.1 5.4-.2 8-.4zM264.8 511.8c-1.4 0-2.8.1-4.3.1 1.4 0 2.8 0 4.3-.1z" />
    </svg>
  );
}

export default Facebook;
