import React, { useEffect } from "react";
import "./Modal.scss";

const Modal = ({ children, isOpen, onClose, className }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("isModalOpen");
    } else {
      document.body.classList.remove("isModalOpen");
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={`modal__background ${
          isOpen ? "modal__background--isOpen" : ""
        }`}
      ></div>
      <div className={`modal ${isOpen ? "modal--isOpen" : ""} ${className}`}>
        <div className="modal__wrapper">
          <button className="modal__closeBtn" onClick={onClose}>
            <span>close</span>
            <svg
              height="311pt"
              viewBox="0 0 311 311.07733"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0"
              ></path>
              <path
                fill="currentColor"
                d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0"
              ></path>
            </svg>
          </button>
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
